class ThinkReservation {
	constructor(props) {
		this.props = props
	}

	init() {

		this.formContainer = this.props.block.querySelector('.thinkreservation-block')
		this.form = this.props.block.querySelector('form')

		this.numberField = this.form.querySelectorAll('.counter')

		if ( this.props.block.classList.contains('popup-enabled') ) {
			this.initPopup()
		}

		this.initNumberField()
		this.extendValidator()

		this.form.addEventListener('submit', (e) => {
			e.preventDefault();

			const constraints = {}
			const formData = new FormData(this.form)
			
			const link = this.form.dataset.url
			const action = this.form.dataset.action
			let shortname = formData.get('hotel') ? formData.get('hotel') : formData.get('shortname'),
				start_date = formData.get('start_date'),
				end_date = formData.get('end_date'),
				number_of_adults = formData.get('number_of_adults'),
				number_of_children = formData.get('number_of_children')
			
			if (this.form.querySelector('select[name=hotel]')) {
				constraints.hotel = {
					presence: true
				}
			}

			if (this.form.querySelector('input[name=start_date]')) {
				constraints.start_date = {
					presence: true,
					datetime: {
						dateOnly: true,
						earliest:  moment.utc().subtract(1, 'days'),
						message: "Date is not valid"
					}
				}
			}

			if (this.form.querySelector('input[name=end_date]')) {
				constraints.end_date = {
					presence: true,
					datetime: {
						dateOnly: true,
						earliest: moment(start_date).utc(),
						message: "Invalid departure date"
					}
				}
			}

			if (this.form.querySelector('input[name=number_of_adults]')) {
				constraints.number_of_adults = {
					numericality: {
						onlyInteger: true,
						greaterThanOrEqualTo: 0
					}
				}
			}

			if (this.form.querySelector('input[name=number_of_children]')) {
				constraints.number_of_children = {
					numericality: {
						onlyInteger: true,
						greaterThanOrEqualTo: 0
					}
				}
			}
	
			validate.validators.presence.options = {message: "^This field is required"};
	
			const errors = validate( this.form, constraints );


			this.resetError(constraints)
	
			if ( !errors ) {
				let query = []
				let params

				if ( start_date ) query.push(`start_date=${start_date}`)
				if ( end_date ) query.push(`end_date=${end_date}`)
				if ( number_of_adults ) query.push(`number_of_adults=${number_of_adults}`)
				if ( number_of_children ) query.push(`number_of_children=${number_of_children}`)

				if (query.length > 0 ) params = `?${query.join('&')}`
				
				window.open( `${link}/${shortname}/${action}${params}` )
			} else {
				this.showError(errors)
			}
		})
	}

	initPopup() {
		const popups = document.querySelectorAll('.tr-popup')

		if ( popups.length > 0 ) {
			this.formContainer.setAttribute('aria-hidden', 'true')
			this.formContainer.setAttribute('aria-modal', 'true')

			popups.forEach(el => {

				const close = this.formContainer.querySelector('.tr-popup-close')

				el.addEventListener('click', e => {
					e.preventDefault()
					this.formContainer.classList.add('popup-open', 'show')
					this.formContainer.setAttribute('aria-hidden', 'false')
					this.formContainer.classList.remove('hide')
				})

				close.addEventListener('click', e => {
					this.formContainer.classList.remove('popup-open', 'show')
					this.formContainer.setAttribute('aria-hidden', 'true')
					this.formContainer.classList.add('hide')
				})
			})
		}
	}

	resetError(fields) {
		for ( const field in fields ) {
			input = this.form.querySelector(`[name=${field}]`)
			if ( input ) {
				inputContainer = input.parentNode

				inputContainer.classList.remove('has-error')
				errorMessage = inputContainer.querySelector('.error-message')

				if ( errorMessage ) {
					inputContainer.removeChild(errorMessage)
				}
			}
		}
	}

	showError(errors) {
		for ( const error in errors ) {
			input = this.form.querySelector(`[name=${error}]`)
			if ( input ) {
				inputContainer = input.parentNode
				errorMessage = document.createElement('span')
				errorMessage.classList.add('error-message')
				errorMessage.innerHTML = errors[error]

				inputContainer.classList.add('has-error')

				if ( !inputContainer.contains(inputContainer.querySelector('.error-message')) ) {
					inputContainer.append(errorMessage);
				}
			}
		}
	}

	initNumberField() {
		if (this.numberField.length > 0) {
			this.numberField.forEach(el => {

				const field = el.querySelector('input')
				const value = parseInt(field.dataset.value)
				const max = parseInt(field.dataset.max)
				const min = parseInt(field.dataset.min)
				const increment = el.querySelector('.increment')
				const decrement = el.querySelector('.decrement')

				field.value = value

				increment.addEventListener('click', (inc) => {
					let current = field.value 
					if (current < max) {
						current++
						field.value = current
					}
				})

				decrement.addEventListener('click', (dec) => {
					let current = field.value 
					if (current > min) {
						current--
						field.value = current
					}
				})
			})
		}
	}

	extendValidator() {
		validate.extend(validate.validators.datetime, {
			// The value is guaranteed not to be null or undefined but otherwise it
			// could be anything.
			parse: function(value, options) {
				return +moment.utc(value);
			},
			// Input is a unix timestamp
			format: function(value, options) {
				let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
				return moment.utc(value).format(format);
			}
		})
	}
}

window.addEventListener('DOMContentLoaded', () => {

	const blocks = document.querySelectorAll('.elmntl-think-reservation')
		
	blocks.forEach(block => {
		new ThinkReservation({block}).init()
	})
})

if (window.acf) {
	window.acf.addAction(
		'render_block_preview/type=think-reservation', (e) => {
			const block = e[0]
			new Posts({block}).init()
		}
	)
}
if ( typeof jQuery !== 'undefined' ) {

		jQuery('.thinkreservation-datepicker.arrival').datepicker({
			dateFormat: 'yy-mm-dd',
			minDate: new Date(),
			onClose: function(dateText, inst) {
				const formContainer = jQuery(this).closest('.elmntl-think-reservation')
				const departure = jQuery(formContainer).find('.thinkreservation-datepicker.departure')

				if (dateText) {
					const arrivalDate = new Date(dateText);
					const departureDate = new Date(jQuery(departure).val())

					const dateMin = new Date(arrivalDate.getFullYear(), arrivalDate.getMonth(), arrivalDate.getDate() + 1)

					jQuery(departure).datepicker('option', 'minDate', dateMin)

					if ( arrivalDate > departureDate || isNaN(Date.parse(departureDate)) ) {
						jQuery(departure).datepicker('setDate', dateMin);
					}
				} else {
					jQuery(departure).val('')
				}
			},
			beforeShow: (input, inst) => showDate(input, inst)
		});

	const showDate = (input, inst) => {
		var el = input.getBoundingClientRect()
		var offsetTop =  jQuery(window).scrollTop()
		var leftPos = el.left + window.scrollX
		var height = jQuery(input).innerHeight()

		var windowTop = jQuery(input).offset().top - offsetTop

		const formContainer = jQuery(input).closest('.elmntl-think-reservation')

		if(formContainer.hasClass('popup-enabled')) {
			window.setTimeout(function () {
				const instHeight = inst.dpDiv.height()

				const computedBottom = input.getBoundingClientRect().top + instHeight + height + 60

				const offsetBottom = window.innerHeight

				if ( computedBottom < offsetBottom ) {
					jQuery(inst.dpDiv).css({ top: (windowTop + height) + 'px', left:leftPos + 'px' })
				} else {
					jQuery(inst.dpDiv).css({ top: (windowTop - height - instHeight - 10) + 'px', left:leftPos + 'px' })
				}
			}, 1);
		}
	}

    jQuery('.thinkreservation-datepicker.departure').datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: new Date(),
		beforeShow: (input, inst) => showDate(input, inst)
    })
}